const relAlternate = 'alternate';
const androidFreeUrl = 'android-app://tunein.player/tunein/profile/';
const androidProUrl = 'android-app://radiotime.player/tunein/profile/';

export function createLandingPageAndroidDeepLinks() {
  return [{ rel: 'alternate', href: 'android-app://radiotime.player/browse' }];
}

export function createAndroidDeepLinks(guideId) {
  return [
    { rel: relAlternate, href: `${androidFreeUrl}${guideId}` },
    { rel: relAlternate, href: `${androidProUrl}${guideId}` },
  ];
}
