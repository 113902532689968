import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// NOTES:
// Structured Data is used to communicate information to Google, which will allow Google to provide user's a richer in-browser experience
// Below are references to explain the purpose behind JSON LD SearchAction implemention - implemented via Landing
// https://developers.google.com/search/docs/appearance/structured-data/sitelinks-searchbox
// https://tunein.atlassian.net/browse/TUNE-9639

// Also, an explanation as to why adding Structured Data to our Profile Pages could be beneficial
// https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data

const SeoHelmet = ({
  title,
  name,
  image,
  url,
  description,
  keywords = [],
  links,
  meta,
  additionalJsonLd,
  includeJsonLd,
}) => {
  const keywordString = keywords?.filter((kw) => kw)?.join(',');
  const structuredJSON = JSON.stringify({
    '@context': 'http://schema.org',
    name: name || 'TuneIn',
    url: url || 'https://tunein.com/',
    image,
    description,
    keywords: keywordString,
    ...additionalJsonLd,
  });

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywordString} />
      {meta?.map((attrs, index) => (
        <meta key={index} {...attrs} />
      ))}
      {links?.map((attrs, index) => (
        <link key={index} {...attrs} />
      ))}
      {includeJsonLd && (
        <script type="application/ld+json">{structuredJSON}</script>
      )}
    </Helmet>
  );
};

SeoHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.array,
  links: PropTypes.array,
  meta: PropTypes.array,
  additionalJsonLd: PropTypes.object,
  includeJsonLd: PropTypes.bool,
};

export default SeoHelmet;
