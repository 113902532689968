import config from 'src/common/config';

export default function fullUrl(relativeUrl, dropQueryParams = false) {
  if (!relativeUrl) {
    return '';
  }

  try {
    const url = new URL(relativeUrl, config.absoluteUrlRoot);

    if (!url.pathname.endsWith('/')) {
      url.pathname += '/';
    }

    if (dropQueryParams) {
      url.search = '';
    }

    return url.toString();
  } catch (e) {
    return '';
  }
}
