function isInvalidUtcTime(utcTime) {
  return (
    typeof utcTime !== 'string' ||
    !utcTime.length ||
    new Date(utcTime).toString() === 'Invalid Date'
  );
}

/**
 * Ensuring the ISO `Z` suffix is present allows Date() to create a localized date
 *  (e.g., Thu Feb 18 2038 18:23:00 GMT-0800 (Pacific Standard Time))
 *
 * @param {string} utcTime - UTC time string (e.g., `2038-02-19T02:23:00`)
 * @returns {string} - localized and formatted time string (e.g., February 18, 2038 6:23 pm)
 */
export default function getDateAndTime(utcTime) {
  if (isInvalidUtcTime(utcTime)) {
    return '';
  }

  const utcTimeSuffix = utcTime.endsWith('Z') ? '' : 'Z';
  const date = new Date(`${utcTime}${utcTimeSuffix}`);

  const monthDayAndYear = date.toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  const formattedTime = date.toLocaleString('default', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return `${monthDayAndYear} ${formattedTime.toLowerCase()}`;
}
