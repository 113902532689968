import { ROOT_PATH } from '../../constants/paths';
import fullUrl from '../../utils/fullUrl';

const DEFAULT_LANGUAGES = ['es', 'en'];

export function createInternationalSeoLinksForRoute(route) {
  const links = DEFAULT_LANGUAGES.map((lang) => ({
    rel: 'alternate',
    href:
      lang === 'en'
        ? `https://tunein.com${route}`
        : `https://tunein.com${route}?lang=${lang}`,
    hreflang: lang,
  }));

  links.push({
    rel: 'alternate',
    href: `https://tunein.com${route}`,
    hreflang: 'x-default',
  });

  return links;
}

export function createLandingPageInternationalSeoLinks() {
  return createInternationalSeoLinksForRoute(ROOT_PATH);
}

export function createInternationalSeoLinks(canonicalPath, languageCode) {
  const formattedUrl = fullUrl(canonicalPath);
  const links = DEFAULT_LANGUAGES.map((lang) => ({
    rel: 'alternate',
    href: lang === 'en' ? formattedUrl : `${formattedUrl}?lang=${lang}`,
    hreflang: lang,
  }));

  if (languageCode && !DEFAULT_LANGUAGES.includes(languageCode)) {
    links.push({
      rel: 'alternate',
      href: `${formattedUrl}?lang=${languageCode}`,
      hreflang: languageCode,
    });
  }

  links.push({ rel: 'alternate', href: formattedUrl, hreflang: 'x-default' });

  return links;
}
