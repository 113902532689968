export const baseMetaKeywords = {
  profile: ['online radio'],
  browse: ['radio', 'online', 'talk', 'music'],
  landing: [
    'radio',
    'online radio',
    'internet radio',
    'live radio',
    'streaming radio',
    'talk radio',
    'music radio',
    'news radio',
    'sports radio',
    'music online',
    'radio stations',
    'npr',
  ],
};

export function createLandingPageMetaKeywords() {
  return baseMetaKeywords.landing;
}

export function createBrowsePageMetaKeywords({ title, keywords }) {
  return keywords
    ? keywords.split(',').map((s) => s.trim())
    : [title, ...baseMetaKeywords.browse];
}

export function createProfilePageMetaKeywords({ title, rootGenre, keywords }) {
  return keywords
    ? keywords.split(',').map((s) => s.trim())
    : [title, rootGenre, ...baseMetaKeywords.profile];
}
