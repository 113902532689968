export const PAGE_TITLE_PODCASTS = 'pageTitle.podcasts';
export const PAGE_TITLE_GAMES = 'pageTitle.games';
export const PAGE_TITLE_RADIO = 'pageTitle.radio';
export const PAGE_TITLE_LANDING = 'pageTitle.landing';
export const PAGE_TITLE_ALBUM_WITH_ARTIST = 'pageTitle.album.withArtist';
export const PAGE_TITLE_SEARCH = 'pageTitle.search';
export const PAGE_TITLE_SEARCH_EMPTY = 'pageTitle.search.empty';
export const PAGE_TITLE_REGION = 'pageTitle.region';
export const PAGE_TITLE_REGION_ROOT_BROWSE = 'pageTitle.regionRootBrowse';
export const PAGE_TITLE_LANGUAGE = 'pageTitle.language';
export const PAGE_TITLE_LANGUAGE_ROOT_BROWSE = 'pageTitle.languageRootBrowse';
export const PAGE_TITLE_MAP_VIEW = 'pageTitle.mapView';
