import escapeRegExp from 'lodash/escapeRegExp';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  PAGE_TITLE_ALBUM_WITH_ARTIST,
  PAGE_TITLE_GAMES,
  PAGE_TITLE_LANDING,
  PAGE_TITLE_LANGUAGE,
  PAGE_TITLE_LANGUAGE_ROOT_BROWSE,
  PAGE_TITLE_MAP_VIEW,
  PAGE_TITLE_PODCASTS,
  PAGE_TITLE_RADIO,
  PAGE_TITLE_REGION,
  PAGE_TITLE_REGION_ROOT_BROWSE,
  PAGE_TITLE_SEARCH,
  PAGE_TITLE_SEARCH_EMPTY,
} from 'src/common/constants/localizations/pageTitles';
import getDateAndTime from 'src/common/utils/date/getDateAndTime';
import {
  isGame,
  isLanguage,
  isPodcast,
  isRegion,
  isRootCategory,
  programTypes,
  types,
} from 'src/common/utils/guideItemTypes';
import joinExisty from 'src/common/utils/joinExisty';
import rootCategoriesData from 'src/data/rootCategories.json';

const { guideIdsToPathnames: rootCategories } = rootCategoriesData;

const byLanguageText = 'by language';
const tuneInSuffix = 'TuneIn';
const seoTitleKeys = {
  general: PAGE_TITLE_RADIO,
  podcasts: PAGE_TITLE_PODCASTS,
  games: PAGE_TITLE_GAMES,
  landing: PAGE_TITLE_LANDING,
  albumWithArtist: PAGE_TITLE_ALBUM_WITH_ARTIST,
  search: PAGE_TITLE_SEARCH,
  searchEmpty: PAGE_TITLE_SEARCH_EMPTY,
  region: PAGE_TITLE_REGION,
  regionRootBrowse: PAGE_TITLE_REGION_ROOT_BROWSE,
  language: PAGE_TITLE_LANGUAGE,
  languageRootBrowse: PAGE_TITLE_LANGUAGE_ROOT_BROWSE,
  mapView: PAGE_TITLE_MAP_VIEW,
};

function buildPageTitle(title, seoTitleKey, getLocalizedText) {
  return `${title} | ${getLocalizedText(seoTitleKey)} | ${tuneInSuffix}`;
}

function createStationPageTitle(
  getLocalizedText,
  title,
  { station = {}, location = {} },
) {
  // If no frequency - international or national station so only title
  // CNN | Free Internet Radio | TuneIn
  if (isEmpty(station.frequency)) {
    return buildPageTitle(title, seoTitleKeys.general, getLocalizedText);
  }

  // If callSign exists in the title, then don't include
  const escapedCallSign = escapeRegExp(station.callSign);
  const callSign =
    !isEmpty(station.callSign) && !new RegExp(escapedCallSign, 'i').test(title)
      ? station.callSign
      : null;

  // If band is AM or FM, then include frequency and band
  const frequencyBand =
    !isEmpty(station.band) && (station.band === 'AM' || station.band === 'FM')
      ? `${station.frequency} ${station.band}`
      : null;

  const stationTitle = joinExisty(
    [title, joinExisty([callSign, frequencyBand], ' '), location.displayName],
    ', ',
  );

  return buildPageTitle(stationTitle, seoTitleKeys.general, getLocalizedText);
}

function createProgramPageTitle(
  getLocalizedText,
  title,
  { program = {}, classification = {} },
) {
  const programType = !isEmpty(program.contentType) ? program.contentType : '';
  if (programType === programTypes.podcast) {
    // No Huddle | Listen to Podcasts On Demand Free | TuneIn
    return buildPageTitle(title, seoTitleKeys.podcasts, getLocalizedText);
  }

  if (isGame(program)) {
    const seoKeyword = ` | ${getLocalizedText(seoTitleKeys.games)}`;
    const league = !isEmpty(classification.sportsLeague)
      ? ` | ${classification.sportsLeague}`
      : '';
    const formattedDate = getDateAndTime(program.eventStartTimeUtc);
    const dateAndTime = !isEmpty(formattedDate) ? ` | ${formattedDate}` : '';

    // Warriors at Celtics | Live Stream Sports Radio | NBA | October 12, 2017 7:00 pm | TuneIn
    return `${title}${seoKeyword}${league}${dateAndTime} | ${tuneInSuffix}`;
  }

  // PageTitle | Free Internet Radio | TuneIn
  return buildPageTitle(title, seoTitleKeys.general, getLocalizedText);
}

function createLanguagePageTitle(getLocalizedText, title) {
  if (title.toLowerCase() === byLanguageText) {
    // Stream Radio By Language | Free Internet Radio | TuneIn
    return getLocalizedText(seoTitleKeys.languageRootBrowse);
  }
  // Stream Radio In Afrikaans | Free Internet Radio | TuneIn
  return getLocalizedText(seoTitleKeys.language, { language: title });
}

function createRegionPageTitle(getLocalizedText, guideId, title) {
  if (guideId === rootCategories.regions) {
    // Stream Radio By Location | Free Internet Radio | TuneIn
    return getLocalizedText(seoTitleKeys.regionRootBrowse);
  }
  // Stream Radio from Africa | Free Internet Radio | TuneIn
  return getLocalizedText(seoTitleKeys.region, { region: title });
}

export function createSearchPageTitle(getLocalizedText, term) {
  // Search results for taylor swift | Free Internet Radio | TuneIn
  const titleText = term
    ? getLocalizedText(seoTitleKeys.search, { term })
    : getLocalizedText(seoTitleKeys.searchEmpty);
  return buildPageTitle(titleText, seoTitleKeys.general, getLocalizedText);
}

export function createBrowsePageTitle({
  getLocalizedText,
  title,
  properties,
  guideId,
}) {
  const rootGenre = get(properties, 'classification.rootGenreClassification');
  if (isPodcast(rootGenre)) {
    // Podcasts | Free Internet Radio | TuneIn
    return buildPageTitle(title, seoTitleKeys.general, getLocalizedText);
  }

  if (isRegion(guideId)) {
    return buildPageTitle(
      createRegionPageTitle(getLocalizedText, guideId, title),
      seoTitleKeys.general,
      getLocalizedText,
    );
  }

  if (isLanguage(guideId)) {
    return buildPageTitle(
      createLanguagePageTitle(getLocalizedText, title),
      seoTitleKeys.general,
      getLocalizedText,
    );
  }

  if (
    (!isRootCategory(guideId) && isEmpty(rootGenre)) ||
    guideId === rootCategories.home ||
    guideId === rootCategories.recents
  ) {
    // PageTitle | Free Internet Radio | TuneIn
    return buildPageTitle(title, seoTitleKeys.general, getLocalizedText);
  }

  // {PageTitle} | Free Internet Radio | TuneIn
  return buildPageTitle(title, seoTitleKeys.general, getLocalizedText);
}

export function createProfilePageTitle({
  getLocalizedText,
  title,
  properties,
  type,
}) {
  if (type === types.station) {
    return createStationPageTitle(getLocalizedText, title, properties);
  }

  if (type === types.program) {
    return createProgramPageTitle(getLocalizedText, title, properties);
  }

  if (
    type === types.album &&
    !isEmpty(properties.albumInfo) &&
    !isEmpty(properties.albumInfo.artistName)
  ) {
    return buildPageTitle(
      getLocalizedText(seoTitleKeys.albumWithArtist, {
        album: title,
        artist: properties.albumInfo.artistName,
      }),
      seoTitleKeys.general,
      getLocalizedText,
    );
  }

  // PageTitle | Free Internet Radio | TuneIn
  return buildPageTitle(title, seoTitleKeys.general, getLocalizedText);
}

export function createLandingPageTitle(getLocalizedText) {
  const seoGeneralKeywords = getLocalizedText(seoTitleKeys.general);
  const seoLandingPageKeywords = getLocalizedText(seoTitleKeys.landing);
  // TuneIn | Free Internet Radio | Live News, Sports, Music, and Podcasts
  return `TuneIn | ${seoGeneralKeywords} | ${seoLandingPageKeywords}`;
}

export function createMapViewPageTitle(getLocalizedText) {
  // TuneIn Explorer - Discover New Radio Stations & Songs from Around the World
  return `TuneIn Explorer - ${getLocalizedText(seoTitleKeys.mapView)}`;
}
