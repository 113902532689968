import isEmpty from 'lodash/isEmpty';
import {
  PAGE_DESCRIPTION_DEFAULT,
  PAGE_DESCRIPTION_DEFAULT_CHANNEL,
} from 'src/common/constants/localizations/pageDescriptions';
import getDateAndTime from 'src/common/utils/date/getDateAndTime';
import {
  isGame,
  isLanguage,
  isRegion,
  programTypes,
  types,
} from 'src/common/utils/guideItemTypes';
import rootCategoriesData from 'src/data/rootCategories.json';
import { TOPIC_META_DESCRIPTION } from '../../constants/localizations/topic';

const { guideIdsToPathnames: rootCategories } = rootCategoriesData;

const byLanguageText = 'by language';
const radioText = ' Radio';
const streamText = 'Stream ';
const sportsText = 'Sports';
const streamRadioText = 'Stream Radio ';
const streamRadioFromText = 'Stream Radio from ';
const streamRadioInText = 'Stream Radio in ';
const freeOnlineText = ' free online.';
const listenToText = 'Listen to ';
const liveStreamGameText1 = 'Listen to play-by-play of every ';
const liveStreamGameText2 = ' game on TuneIn Radio.';
const featuringSongsFromText = ' featuring songs from ';
const podcastOnDemandText = ' podcast on demand -';
const seoDescriptionKeys = {
  default: PAGE_DESCRIPTION_DEFAULT,
  defaultChannel: PAGE_DESCRIPTION_DEFAULT_CHANNEL,
};

function createDefaultDescriptionTitle(title) {
  return `${streamText}${title}${freeOnlineText}`;
}

function buildPageDescription(getLocalizedText, title, description) {
  return description
    ? `${title} ${description}`
    : `${title} ${getLocalizedText(seoDescriptionKeys.default)}`;
}

function buildBrowsePageDescription(getLocalizedText, title, description) {
  return (
    description ||
    getLocalizedText(seoDescriptionKeys.defaultChannel, { title })
  );
}

function createStationDescription({
  getLocalizedText,
  title,
  description,
  location,
}) {
  if (description) {
    return `${title} - ${description}`;
  }

  return !isEmpty(location)
    ? buildPageDescription(
        getLocalizedText,
        `${title} - ${location.displayName} -`,
      )
    : buildPageDescription(getLocalizedText, `${title} -`);
}

function createProgramDescription({
  getLocalizedText,
  title,
  description,
  program,
  classification,
}) {
  const programType = !isEmpty(program.contentType) ? program.contentType : '';

  if (programType === programTypes.podcast) {
    // No Huddle podcast on demand - {description/defaultDescription}
    return buildPageDescription(
      getLocalizedText,
      `${title}${podcastOnDemandText}`,
      description,
    );
  }

  if (isGame(program)) {
    const league = !isEmpty(classification.sportsLeague)
      ? classification.sportsLeague
      : '';
    const formattedDate = getDateAndTime(program.eventStartTimeUtc);
    const dateAndTime = !isEmpty(formattedDate) ? ` on ${formattedDate}` : '';
    // eslint-disable-next-line max-len
    // Stream NBA Radio - Warriors at Celtics on October 12, 2017 7:00 pm. Listen to play-by-ply of every NBA game on TuneIn Radio.
    // eslint-disable-next-line max-len
    return `${streamText}${
      league || sportsText
    }${radioText} - ${title}${dateAndTime}. ${liveStreamGameText1}${
      league || sportsText.toLowerCase()
    }${liveStreamGameText2}`;
  }

  return buildPageDescription(
    getLocalizedText,
    createDefaultDescriptionTitle(title),
    description,
  );
}

function createLanguageDescription(title) {
  if (title.toLowerCase() === byLanguageText) {
    // Stream Radio By Language free online.
    return `${streamRadioText}${title}${freeOnlineText}`;
  }
  // Stream Radio In Afrikaans free online.
  return `${streamRadioInText}${title}${freeOnlineText}`;
}

function createRegionPageDescription(guideId, title) {
  if (guideId === rootCategories.regions) {
    // Stream Radio By Location free online.
    return `${streamRadioText}${title}${freeOnlineText}`;
  }
  // Stream Radio from Africa free online.
  return `${streamRadioFromText}${title}${freeOnlineText}`;
}

function createArtistDescription(getLocalizedText, artistInfo) {
  // Listen to {Artist Name} Radio free online.
  // eslint-disable-next-line max-len
  const title = `${listenToText}${artistInfo.artistName}${radioText}${freeOnlineText}`;
  return buildPageDescription(getLocalizedText, `${title}`);
}

function createAlbumDescription(getLocalizedText, albumInfo) {
  // Listen to {Artist Name} Radio featuring songs from {album name} free online.
  // eslint-disable-next-line max-len
  const title = `${listenToText}${albumInfo.artistName}${radioText}${featuringSongsFromText}${albumInfo.albumName}${freeOnlineText}`;
  return buildPageDescription(getLocalizedText, `${title}`);
}

export function createProfileMetaDescription({
  getLocalizedText,
  type,
  title,
  description,
  program = {},
  classification = {},
  location = {},
  artistInfo = {},
  albumInfo = {},
}) {
  switch (type) {
    case types.station:
      return createStationDescription({
        getLocalizedText,
        title,
        description,
        location,
      });
    case types.program:
      return createProgramDescription({
        getLocalizedText,
        title,
        description,
        program,
        classification,
      });
    case types.topic:
      return getLocalizedText(TOPIC_META_DESCRIPTION, {
        profileTitle: title,
        topicDescription: description,
      });
    case types.artist:
      return createArtistDescription(getLocalizedText, artistInfo);
    case types.album:
      return createAlbumDescription(getLocalizedText, albumInfo);
    default:
      return buildPageDescription(
        getLocalizedText,
        createDefaultDescriptionTitle(title),
        description,
      );
  }
}

export function createBrowsePageMetaDescription({
  getLocalizedText,
  guideId,
  title,
  description,
}) {
  if (guideId === rootCategories.home || guideId === rootCategories.recents) {
    return getLocalizedText(seoDescriptionKeys.default);
  }

  if (isRegion(guideId)) {
    return createRegionPageDescription(guideId, title);
  }

  if (isLanguage(guideId)) {
    return createLanguageDescription(title);
  }

  return buildBrowsePageDescription(getLocalizedText, title, description);
}

export function createLandingPageMetaDescription(getLocalizedText) {
  return getLocalizedText(seoDescriptionKeys.default);
}
